import { replaceDocument } from "@with-cardinal/client-router";
import { ClientRouter } from "@with-cardinal/client-router";
import { Fx } from "@with-cardinal/fx";

export class BetterForm extends Fx {
	attach() {
		this.form = this.querySelector("form");

		this.listen(this.form, "submit", (evt) => this.onSubmit(evt));

		if (this.dataset.submitOnChange !== undefined) {
			this.listen(this.form, "change", () => this.onSubmit());
		}
	}

	// called when a form submission is successful, i.e. status < 400
	// eslint-disable-next-line no-unused-vars
	async onSuccess(resp) {
		return;
	}

	// called when a form submission is unsuccessful, i.e. status >= 400
	// eslint-disable-next-line no-unused-vars
	async onError(resp) {
		return;
	}

	async preprocess() {
		const needProcessing = this.querySelectorAll('[data-form-ready="false"]');
		for (const elem of needProcessing) {
			try {
				const result = await elem.preprocess();
				if (!result) {
					return result;
				}
			} catch (e) {
				console.error(e);
				return false;
			}
		}

		return true;
	}

	async onSubmit(evt = undefined) {
		if (evt) {
			evt.preventDefault();
		}

		this.disableSubmit(true);

		document.body.classList.add("client-router-loading");

		const preprocessResult = await this.preprocess();
		if (!preprocessResult) {
			alert("An error occurred");
			document.body.classList.remove("client-router-loading");
			return;
		}

		const action = this.form.action;

		if (this.form.method === "get") {
			ClientRouter.push(
				`${action}?${new URLSearchParams(new FormData(this.form))}`,
			);
			document.body.classList.remove("client-router-loading");
			return;
		}

		const resp = await fetch(action, {
			method: "post",
			body: new URLSearchParams(new FormData(this.form)),
		});

		this.enableSubmit();

		if (resp.status < 400) {
			this.onSuccess(resp);
		} else {
			this.onError(resp);
		}

		if (resp.headers.has("Content-Location")) {
			ClientRouter.push(resp.headers.get("Content-Location"), true);
		} else {
			replaceDocument(resp);
			ClientRouter.pushUrl(resp.url);
		}
		document.body.classList.remove("client-router-loading");
	}

	submitButtons() {
		return this.form.querySelectorAll("button[type='submit']");
	}

	disableSubmit(submitting = false) {
		for (const el of this.submitButtons()) {
			el.setAttribute("disabled", "true");
			el.dataset.submitting = submitting;
		}
	}

	enableSubmit() {
		for (const el of this.submitButtons()) {
			el.removeAttribute("disabled");
			el.dataset.submitting = undefined;
		}
	}
}
